<template>
	<div style="background: #f9f9fb;height: 100vh;">
		<div style="padding: 20px;">
			<el-select v-model="value" style="width: 100%;" placeholder="请选择">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			
			<div class="navbar-list">
				<ul class="flex-imd">
					<li v-for="(item,index) in tabsList" :key="index" style="position: relative;">
						<div class="navbar-item" :class="currNavbar===item.key?'act':''" @click="chooseNav" :data-navid='item.key'>{{item.title}}</div>
						<div style="border-bottom: 1px solid #d1102d;position: absolute;width: 100%;bottom: 0;" v-if="currNavbar===item.key"></div>
					</li>
				</ul>
			</div>
			
			<div :style="list.length>2 ? 'margin-bottom:80px;' : ''">
				<div style="background: #fff;padding: 20px;border-radius: 10px;box-shadow: 1px 4px 18px 0px rgb(122 122 122 / 20%);margin-bottom: 20px;" v-if="userInfo!==null" v-for="(item,index) in list" :key="index">
					<div style="display: flex;position: relative;">
						<div style="font-size: 14px;font-weight: bold;">订单号：{{item.orderno}}</div>
						<div style="position: absolute;right: 0;" :style="item.orderstatus==0 ? 'color:orange' : item.orderstatus==1 ? 'color:orange' : item.orderstatus == 2 ? 'color:red' : item.orderstatus == 3 ? 'color:blue' : item.orderstatus == 4 ? 'color:green' : 'color:purple'">{{translate(1,item.orderstatus)}}</div>
					</div>
					<div style="padding: 10px 0;" v-if="value==1">
						{{item.pcblength}}x{{item.pcbwidth}}cm,{{dataList.plateTG[item.pcbbrand-1].name}},
						{{dataList.numberBoardLayers[item.pcblayer-1].name}},{{dataList.minimumThroughHole[item.pcbminhole-1].name}},
						{{dataList.resistanceWeldingColor[item.pcbholecolor-1].name}},{{dataList.solderPadSprayCoating[item.pcbholespray-1].name}},
						{{dataList.copperThickness[item.pcbcuthick-1].name}}
					</div>
					<div style="padding: 10px 0;" v-if="value==2">
						pcb数量:{{item.smtboardcount}},元器件数量:{{item.smtcompcount}},{{item.smttechreq ? dataList.SMTProcessRequirements[item.smttechreq-1].name : ''}},{{item.smtisbake==1?'需要烘焙':'不需要烘焙'}},
						{{dataList.invoicingMethod[item.invoicetype-1].name}}
					</div>
					<div style="padding: 10px 0;" v-if="value==3">
						钢网数量:{{item.stencount}},{{dataList.steelMeshType[item.stentype-1].name}},{{item.stentype==1 ? dataList.steelMeshSpecifications1[item.stenspec-1].name : item.stentype==2 ? dataList.steelMeshSpecifications2[item.stenspec-1].name : dataList.steelMeshSpecifications3[item.stenspec-1].name}},
						{{dataList.steelMeshThickness[item.stenthick-1].name}},{{dataList.steelMeshProductionMethod[item.stenway-1].name}},{{dataList.invoicingMethod[item.invoicetype-1].name}}
					</div>
					<div style="padding: 10px 0;" v-if="value==4">
						文件名:{{item.filename}}
					</div>
					<div style="color: #bfbfbf;padding-bottom: 10px;" v-if="value==1 || value==2">
						数量：{{item.pcbcount}}
					</div>
					<div style="padding-bottom: 10px;display: flex; flex-direction: row; justify-content: flex-end;">
						<div >总计：</div>
						<div style="color: red;" v-if="value==1">{{item.pcbprice}}</div>
						<div style="color: red;" v-if="value==2">{{item.smtfee}}</div>
						<div style="color: red;" v-if="value==3">{{item.stentfee}}</div>
						<div style="color: red;" v-if="value==4">审核后报价</div>
					</div>
					<div style="display: flex;justify-content: right;position: relative;height: 40px;align-items: center;">
						<div style="position: absolute;right: 0;">
							<el-button size="mini" @click="detailJump(item)">查看详情</el-button>
						</div>
					</div>
				</div>
			</div>
			
			<el-empty description="暂未登录" v-if="userInfo==null"></el-empty>
			<el-empty description="暂无数据" v-if="userInfo!==null && list.length==0"></el-empty>
		</div>
		
		<applet-bottom></applet-bottom>
	</div>
</template>

<script>
	import appletBottom from '@/components/bottom/appletIndex.vue';
	import {
		dataInJs
	} from '@/business/valuation/components/data.js';
	import {
		pageByField,
	} from '@/api/orderManagement/index'
import { Store } from 'vuex';
	export default {
		components: {
			appletBottom
		},
		data() {
			return {
				options: [{
					value: 1,
					label: 'PCB订单管理'
				}, {
					value: 2,
					label: 'SMT订单管理'
				}, {
					value: 3,
					label: '钢网订单管理'
				}, {
					value: 4,
					label: 'BOM订单管理'
				}],
				value: 1,
				tabsIndex: 0, //tabs下标
				currNavbar:0,
				tabsList:[{
					title: '全部订单',
					key: -1
				}, {
					title: '待报价',
					key: 0
				}, {
					title: '已报价',
					key: 4
				}, {
					title: '审核中',
					key: 5
				}, {
					title: '生产中',
					key: 6
				}, {
					title: '已完成',
					key: 11
				}, {
					title: '已取消',
					key: 99
				}],
				panesOption: [{
					title: '全部订单',
					key: undefined
				}, {
					title: '待报价',
					key: 0
				}, {
					title: '待报价',
					key: 1
				}, {
					title: '待报价',
					key: 2
				}, {
					title: '待报价',
					key: 3
				}, {
					title: '已报价',
					key: 4
				}, {
					title: '审核中',
					key: 5
				}, {
					title: '生产中',
					key: 6
				}, {
					title: '生产中',
					key: 7
				}, {
					title: '生产中',
					key: 8
				}, {
					title: '生产中',
					key: 9
				}, {
					title: '生产中',
					key: 10
				}, {
					title: '已完成',
					key: 11
				}, {
					title: '已取消',
					key: 99
				}],
				userInfo:null,
				//列表数据
				list: [],
				//总数量
				total: 0,
				dataList: dataInJs(), //字典list数据
				// 传参
				queryParam: {
					pageNum: 1,
					pageSize: 10,
					orderInfo: {
						orderattr: 1,
						uid: undefined,
						orderstatus: undefined, //订单状态
						orderno: undefined, //订单号
						creatsdate: undefined, //下单开始时间
						createdate: undefined, //下单结束时间
					},
				},
			}
		},

		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			this.value = this.$store.state.selectForm.weixinOrderIndex;
			// console.log(this.dataList.SMTProcessRequirements)
			if(this.userInfo!==null){
				this.pageByFieldData(0);
			}
		},

		mounted() {

		},

		watch: {
			value(val){
				if(this.userInfo!==null){
					this.currNavbar=0;
					this.pageByFieldData(0);
				}
			}
		},

		computed: {

		},

		methods: {
			
			//----------------------- 获取订单列表数据 -----------------------------
			pageByFieldData: function(e) {
				if(this.value==1){
					var data = {
						orderInfo: {
							orderattr: 1,
							uid:this.userInfo.uid,
							orderstatus: e == -1 ? undefined : e, //订单状态
						},
					}
				}else if(this.value==2){
					var data = {
						orderInfo: {
							orderattr: 2,
							uid:this.userInfo.uid,
							orderstatus: e == -1 ? undefined : e, //订单状态
						},
					}
				}else if(this.value==3){
					var data = {
						orderInfo: {
							orderattr: 3,
							uid:this.userInfo.uid,
							orderstatus: e == -1 ? undefined : e, //订单状态
						},
					}
				}else{
					var data = {
						orderInfo: {
							orderattr: 4,
							uid:this.userInfo.uid,
							orderstatus: e == -1 ? undefined : e, //订单状态
						},
					}
				}
				pageByField(data).then(res => {
					// console.log(res)
					this.list = []
					if(res.data!==null){
						this.$nextTick(() => {
							this.list = res.data.content
							this.total = res.data.totalSize
						})
					}else{
						this.list = []
						this.total = 0
					}
				})
			},
			
			chooseNav (e) {
			      this.currNavbar = Number(e.target.dataset.navid)
				  // console.log(Number(e.target.dataset.navid))
			       	// 当前点击元素的左边距离
			        const distanceL = document.getElementsByClassName('navbar-item act')[0].getBoundingClientRect().left
			         // 点击元素的一半宽度
			        const elementHW = document.getElementsByClassName('navbar-item act')[0].clientWidth / 2
			        // 屏幕宽度
			        const screenW = document.body.clientWidth
			        // 屏幕宽度的一半
			        const screenHW = document.body.clientWidth / 2
			        // 元素右边距离
			        const distanceR = screenW - distanceL
			        // 获取最外层的元素
			        const scrollBox = document.getElementsByClassName('navbar-list')
			        // 滚动条滚动的距离
			        const scrollL = scrollBox[0].scrollLeft
			        // 当元素左边距离大于屏幕一半宽度  或者  右边距离大于屏幕一半距离的时候
			        if (distanceL > (screenHW - elementHW) || distanceR > (screenHW - elementHW)) {
			           // 滚动条最终的滚动距离
			          scrollBox[0].scrollLeft = scrollL + (distanceL - screenHW + elementHW)
			        }
				if(this.userInfo!==null){
					this.pageByFieldData(Number(e.target.dataset.navid));
				}
			},
			
			//-----------------订单状态筛选------------------------
			translate: function(e, key) {
				if (e == 1) {
					let a = null
					this.panesOption.filter(res => {
						if (key == res.key) {
							a = res.title
						}
					})
					return a
				}
			},
			
			//------------------跳转订单详情页面-------------------------
			detailJump:function(item){
				this.$router.push({
					path: '/orderDetail',
					query:{
						id:item.id,
						value:this.value
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.navbar-list{
	  margin: 0 0 20px 0;
	  width: 100%;
	  height: 32px;
	  position: relative;
	  margin-top: 20px;
	  overflow-x: scroll;
	}
	.navbar-list>ul{
	  /* padding: 0 20px; */
	  position: absolute;
	  left: 0;
	  height: 32px;
	  display: flex;
	  flex-flow: row nowrap;
	}
	.navbar-item{
	  /* width: 120px; */
	  width: max-content;
	  padding: 0px 18px;
	  box-sizing: border-box;
	  display: flex;
	  flex-flow: column nowrap;
	  justify-content: space-around;
	  align-items: center;
	  cursor: pointer;
	  /* margin-right: 10px; */
	  color: #000;
	}
	.navbar-list::-webkit-scrollbar {
	  display: none;
	}
	.act{
	  // background-image: -webkit-linear-gradient(left, #d1102d, #d1102d);
	  // -webkit-background-clip: text;
	  // -webkit-text-fill-color: transparent;
	  color: #d1102d;
	  font-weight: bold;
	}
	li {
		list-style: none;
		padding: 0px;
		margin: 0px;
	}
</style>